import styled from 'styled-components'

export const StyledOperatorsContainer = styled.div`
  margin: 0 0 80px 0;
`
export const StyledBackButton = styled.div`
  padding: 12px 0;
  display: flex;
`

export const StyledGroupChecks = styled.div`
  padding: 2%;
`

export const StyledModuleChecks = styled.div`
  margin: 10px 0 0 30px;
`

export const StyledGroupNameTitle = styled.p`
  font-weight: 600;
  color: #0b4793;
  margin: 0;
`

export const StyledModuleNameTitle = styled.p`
  margin: 5px 0;
  font-weight: 600;
`

export const StyledActionNameTitle = styled.p`
  margin: 0;
`

export const StyledActionDescription = styled.p`
  margin: 0;
  color: #828282;
  font-size: 12px;
`
export const StyledHR = styled.hr`
  background-color: #d2d2d2;
  height: 1px;
  border: 0;
`
