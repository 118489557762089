import React from 'react'
import SearchEngineOptimization from '../../components/SEO'
import OperatorsContainer from '../../containers/OperatorsContainer'
import WithPrivateRoute from '../../higherOrderComponents/WithPrivateRoute'
import LayoutMain from '../../layout'

const OperatorsPage: React.FC = () => {
  return (
    <LayoutMain>
      <SearchEngineOptimization title="Operadores" />
      <WithPrivateRoute component={OperatorsContainer} />
    </LayoutMain>
  )
}

export default OperatorsPage
