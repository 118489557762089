// Packages
import React from 'react'
// Style
import {
  StyledButton,
  StyledHeaderModuleContainer,
  StyledTitlePage
} from './styledComponents'

type props = {
  title: string
  backButton?: any
  button?: any
}

const HeaderModule: React.FC<props> = ({ title, button, backButton }) => {
  return (
    <StyledHeaderModuleContainer>
      <StyledTitlePage>
        {backButton}
        {title}
      </StyledTitlePage>
      <StyledButton>{button}</StyledButton>
    </StyledHeaderModuleContainer>
  )
}

export default HeaderModule
