import styled from 'styled-components'

export const StyledHeaderModuleContainer = styled.div`
  display: flex;
  padding: 10px;
`

export const StyledTitlePage = styled.h2`
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  display: flex;
  align-items: center;
`

export const StyledButton = styled.div`
  width: 80%;
`
