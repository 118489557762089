// Packages
import React from 'react'
import { SwitchProps } from 'antd'

// Styled components
import { StyledSwitch } from './styledComponents'

const Switch: React.FC<SwitchProps> = (props) => {
  return <StyledSwitch {...props} data-testid="sw-btn" />
}

export default Switch
