// Packages
import React from 'react'
import { isEmpty } from 'lodash'
// Components
import OperatorCardInfo from '../OperatorCardInfo'
// Styled
import {
  StyledImgOperatorContainer,
  StyledImgNoDataOperator,
  StyledTextNoDataOperator
} from './styledComponents'
// Assets
import noDataWorkers from '../../data/assets/icons/no-data-workers.svg'

type props = {
  operators: any
  handleOperatorEdit: any
  handleOperatorStatus: any
}

const OperatorsList: React.FC<props> = ({
  operators,
  handleOperatorEdit,
  handleOperatorStatus
}) => {
  const emptyList = (
    <StyledImgOperatorContainer>
      <StyledImgNoDataOperator src={noDataWorkers} />
      <StyledTextNoDataOperator>
        Aún no tiene operadores creados.
      </StyledTextNoDataOperator>
    </StyledImgOperatorContainer>
  )

  if (isEmpty(operators)) return emptyList

  return (
    <>
      {operators.map(
        ({
          collaboratorId,
          statusID,
          name,
          middleName,
          familyName,
          mobilePhone
        }: any) => (
          <OperatorCardInfo
            key={collaboratorId}
            collaboratorId={collaboratorId}
            statusID={statusID}
            name={name}
            middleName={middleName}
            familyName={familyName}
            mobilePhone={mobilePhone}
            handleOperatorEdit={handleOperatorEdit}
            handleOperatorStatus={handleOperatorStatus}
          />
        )
      )}
    </>
  )
}

export default OperatorsList
