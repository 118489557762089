// Packages
import React, { useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { navigate } from 'gatsby'
import { LeftOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify'
// Components
import HeaderModule from '../../components/HeaderModule'
import OperatorsList from '../../components/OperatorsList'
import Loading from '../../components/Shared/Loading'
import CustomButton from '../../components/Storybook/customButton'
import { StyledButtonPrimary } from '../../components/Shared/Button/styledComponent'
// GraphQL
import { GET_OPERATORS } from '../../graphql/queries/operators'
import { UPDATE_OPERATOR_STATUS } from '../../graphql/mutation/operators'
// Styled
import { StyledBackButton, StyledOperatorsContainer } from './styledComponents'

const OperatorsContainer: React.FC = () => {
  const {
    data: workerListData,
    loading: workerListLoading,
    refetch: workerListRefetch
  } = useQuery(GET_OPERATORS, {
    fetchPolicy: 'cache-and-network'
  })

  const [
    updateCollaboratorStatus,
    {
      error: errorUpdateCollaboratorStatus,
      loading: loadingUpdateCollaboratorStatus
    }
  ] = useMutation(UPDATE_OPERATOR_STATUS)

  useEffect(() => {
    if (errorUpdateCollaboratorStatus && !loadingUpdateCollaboratorStatus) {
      toast.error('Ha ocurrido un error al editar el operador')
    }
  }, [errorUpdateCollaboratorStatus])

  const createOperatorsButton = (
    <StyledButtonPrimary
      type="button"
      onClick={() => navigate('/operators/create-operator')}
    >
      {'Crear Operador'}
    </StyledButtonPrimary>
  )

  const backButton = (
    <StyledBackButton>
      <CustomButton
        id="button-icon-back-sales-report"
        name="buttonIconBackSalesReport"
        className="button-icon-back-operators"
        dataTestId="data-test-button-icon-back-sales-report"
        icon={<LeftOutlined />}
        onClick={() => navigate('/dashboard')}
      />
    </StyledBackButton>
  )

  const handleOperatorEdit = (collaboratorId: number) =>
    navigate(`edit-operator?collaboratorId=${collaboratorId}`)

  const handleOperatorStatus = async ({
    collaboratorId,
    checked,
    name
  }: any) => {
    const statusID = checked ? 1 : 0
    await updateCollaboratorStatus({
      variables: {
        id: collaboratorId,
        statusID
      }
    })

    const messageActivate = checked ? 'activado' : 'desactivado'
    toast.success(`El operador ${name} ha sido ${messageActivate} con éxito`)
    workerListRefetch()
  }

  if (workerListLoading) return <Loading />

  return (
    <StyledOperatorsContainer>
      <HeaderModule
        title="Operadores"
        button={createOperatorsButton}
        backButton={backButton}
      />
      <OperatorsList
        operators={workerListData?.getCollaborators}
        handleOperatorEdit={handleOperatorEdit}
        handleOperatorStatus={handleOperatorStatus}
      />
    </StyledOperatorsContainer>
  )
}

export default OperatorsContainer
