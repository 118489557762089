// Packages
import React from 'react'
// Components
import Switch from '../Storybook/Switch'
import IconComponent from '../../components/Icon'
// Styled
import {
  StyledStatusOperator,
  StyledOperatorCardInfoContainer,
  StyledCircle,
  StyledNameOperator,
  StyledContent,
  StyledEdit
} from './styledComponents'
// Assets
import EditIcon from '../../data/assets/icons/edit-worker.svg'

type props = {
  collaboratorId: number
  statusID: boolean
  name: string
  middleName: string
  familyName: string
  mobilePhone: string
  handleOperatorEdit: any
  handleOperatorStatus: any
}

const OperatorCardInfo: React.FC<props> = ({
  collaboratorId,
  statusID,
  name,
  middleName,
  familyName,
  mobilePhone,
  handleOperatorEdit,
  handleOperatorStatus
}) => {
  return (
    <StyledOperatorCardInfoContainer size="small">
      <StyledStatusOperator>
        <StyledNameOperator>
          <StyledCircle isActive={statusID} />
          <span>
            {name} {middleName} {familyName}
          </span>
        </StyledNameOperator>
        <Switch
          defaultChecked={statusID}
          onChange={(checked) =>
            handleOperatorStatus({
              collaboratorId,
              checked,
              name: `${name} ${middleName}`
            })
          }
        />
      </StyledStatusOperator>
      <StyledContent>
        <p>{mobilePhone}</p>
        <StyledEdit onClick={() => handleOperatorEdit(collaboratorId)}>
          <IconComponent key={'edit'} icon={EditIcon} height={16} width={16} />
          <p>Editar</p>
        </StyledEdit>
      </StyledContent>
    </StyledOperatorCardInfoContainer>
  )
}

export default OperatorCardInfo
