import styled from 'styled-components'

export const StyledImgOperatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
`

export const StyledImgNoDataOperator = styled.img`
  width: 20%;
  @media all and (min-width: 480px) and (max-width: 768px) {
    width: 50%;
  }
  @media all and (max-width: 480px) {
    width: 80%;
  }
`
export const StyledTextNoDataOperator = styled.p`
  color: #707070;
  width: 100%;
  text-align: center;
  margin: 20px;
`
