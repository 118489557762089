import { gql } from '@apollo/client'

export const GET_OPERATORS = gql`
  query {
    getCollaborators {
      collaboratorId
      name
      middleName
      familyName
      mobilePhone
      statusID
    }
  }
`

export const GET_OPERATOR_BY_ID = gql`
  query getCollaborator($collaboratorId: ID!) {
    getCollaborator(collaboratorId: $collaboratorId) {
      collaboratorId
      name
      middleName
      familyName
      birthdate
      genderID
      mobilePhone
      roleID
      statusID
      permissions {
        groupName
        groupID
        modules {
          moduleName
          descriptionModule
          appModulePemID
          actions {
            actionID
            actionName
            actionDescription
          }
        }
      }
    }
  }
`
export const GET_PERMISSIONS_LIST = gql`
  query getPermissions {
    getPermissions {
      groupName
      groupID
      modules {
        moduleName
        descriptionModule
        appModulePemID
        actions {
          actionID
          actionName
          actionDescription
        }
      }
    }
  }
`
