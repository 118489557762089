// Packages
import styled from 'styled-components'
import { Switch } from 'antd'

export const StyledSwitch = styled(Switch)`
  &.ant-switch {
    background-color: #828282;
  }

  &.ant-switch-checked {
    background-color: #009632;
  }
`
