import styled from 'styled-components'
import { Card } from 'antd'

export const StyledOperatorCardInfoContainer = styled(Card)`
  width: 100%;
  margin: 0 0 10px 0px !important;
  border: none !important;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
`

export const StyledStatusOperator = styled.div`
  display: flex;
  justify-content: space-between;
`

interface Circle {
  isActive: boolean
}

export const StyledCircle = styled.p<Circle>`
  margin: 0;
  background: ${({ isActive }) => (isActive ? '#009632' : '#828282')};
  height: 22px;
  width: 22px;
  min-width: 22px;
  color: white;
  border: 5px solid white;
  border-radius: 50%;
`

export const StyledNameOperator = styled.div`
  display: flex;
  span {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #18212e;
  }
`

export const StyledContent = styled.div`
  padding-left: 20px;
`

export const StyledEdit = styled.div`
  display: flex;
  img {
    margin: 10px 0;
  }
  p {
    padding-left: 10px;
    color: rgb(0 0 0 / 0.4);
  }
`
